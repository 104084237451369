import React, { createContext, useContext, useEffect, useState } from "react"

const ThemeContext = createContext({
  theme: "",
  toggleTheme: () => {},
})

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme")

    if (savedTheme) {
      return savedTheme
    } else {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    }
  }) // default theme

  useEffect(() => {
    document.body.className = theme
    localStorage.setItem("theme", theme)
  }, [theme])

  const toggleTheme = () => {
    console.log("lamaw")
    setTheme(currentTheme => (currentTheme === "light" ? "dark" : "light"))
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

// Hook for easy access to the theme context
export const useTheme = () => useContext(ThemeContext)
