import React, { useState, useEffect } from "react"

const IS_DEV = process.env.NODE_ENV === "development"
export const CookieConsent = () => {
  const [hasConsent, setHasConsent] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const consentStatus = localStorage.getItem("cookieConsent")

    if (!consentStatus) {
      setShowBanner(true)
      // Default state - no consent

      if (!IS_DEV) {
        window.gtag("consent", "default", {
          analytics_storage: "denied",
          ad_storage: "denied",
        })
      }
    } else {
      setHasConsent(consentStatus === "accepted")
      // Restore previous consent state

      if (!IS_DEV) {
        window.gtag("consent", "default", {
          analytics_storage:
            consentStatus === "accepted" ? "granted" : "denied",
          ad_storage: "denied", // Always deny ad storage for privacy
        })
      }
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted")
    setHasConsent(true)
    setShowBanner(false)

    if (!IS_DEV) {
      // Update consent state and trigger page view
      window.gtag("consent", "update", {
        analytics_storage: "granted",
      })

      // Send initial pageview after consent
      window.gtag("event", "page_view", {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      })
    }
  }

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined")
    setHasConsent(false)
    setShowBanner(false)

    if (!IS_DEV) {
      window.gtag("consent", "update", {
        analytics_storage: "denied",
      })
    }
  }

  if (!showBanner) return null

  return (
    <div className="cookie-container">
      <div className="cookie-content">
        <p className="cookie-message">
          🍪 This site uses cookies for analytics.
        </p>
        <div className="cookie-button-container">
          <button onClick={handleAccept} className="accept-button">
            Accept
          </button>
          <button onClick={handleDecline} className="decline-button">
            Decline
          </button>
        </div>
      </div>
    </div>
  )
}
