import React from "react"
// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/poppins"

// :: For code snippets/blocks
import "@fontsource/fira-code"

// normalize CSS across browsers
import "./src/normalize.css"

// custom CSS styles
import "./src/style.css"
import "animate.css"

// Highlighting for code blocks
import "./src/gruvbox.css"

import { ThemeProvider } from "./src/context/ThemeContext"
import { CookieConsent } from "./src/components/CookieConsent"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    {element} <CookieConsent />
  </ThemeProvider>
)
